import Layout from "../../components/layout";
import PageTitle from "../../components/pagetitle";
import Seo from "../../components/seo";
import * as React from "react";
import {StaticImage} from "gatsby-plugin-image";

const AlexandriaPage = () => (
    <Layout>
        <PageTitle title="Alexandria, VA" page="PageTitleAbout" />
        <Seo title="Alexandria, VA Managed Services"
             description="New Vertical offers Managed Services and Software services to Alexandria, Virginia."
             pathname={"/locations/alexandria/"}/>
        <div className="content-page-wrapper">
            <div className="page-intro">
                <h2>Managed Services and Software In Alexandria, VA</h2>
                <StaticImage
                    style={{
                        gridArea: "1/1",
                    }}
                    layout="fullWidth"
                    loading="eager"
                    aspectRatio={3 / 1}
                    alt="Our team will help"
                    // Original Photo by Austin Distel Unsplash
                    src={
                        "../../images/alexandria.jpg"
                    }
                    formats={["auto", "jpg", "avif"]}
                />

                <p>The city of Alexandria, Virginia is a vibrant and thriving metropolis of over 159,000 residents located on the Potomac River just outside of Washington, D.C. Alexandria is often known as a technology hub, due to its proximity to the nation’s capital and its high-tech infrastructure. Businesses of all sizes have access to a wide range of technology services in Alexandria, Virginia, including high-speed internet, managed IT services, cloud computing, and data storage.
                </p>
                <h3>High-Speed Internet</h3>

                <p> Alexandria is home to a variety of high-speed internet providers, offering broadband, fiber-optic, and mobile service. Businesses and residents can choose from a range of plans and speeds, helping them stay connected even when working from home or on the go.</p>

                <h3>Managed IT Services</h3>

                <p>Many businesses rely on managed IT services to take care of their day-to-day computing needs. Managed IT providers in Alexandria are experienced in providing comprehensive IT solutions, including software and hardware deployment, network security, and cloud storage.</p>

                <h3> Cloud Computing</h3>

                <p>Cloud computing is becoming increasingly popular in Alexandria, as businesses of all sizes look for ways to reduce costs and increase productivity. Cloud computing services range from web hosting and email to collaboration tools and data storage.</p>

                <h3>Data Storage</h3>

                <p>Data storage is essential for businesses of any size, and Alexandria businesses have access to reliable and secure data storage solutions. From dedicated servers to cloud-based storage, businesses can find a range of options to meet their data storage needs.</p>

                <p>With its high-tech infrastructure and access to a wealth of technology services, it’s no wonder that Alexandria is a hub for tech businesses. From managed IT services to cloud computing and data storage, Alexandria businesses have access to the best technology services in the area.</p>
            </div>
        </div>
    </Layout>
)

export default AlexandriaPage